import { QtmButton } from '@qtm/react';

import './bottomToolBar.css';

type Props = {
  backDisabled?: boolean;
  nextDisabled?: boolean;
  children?: React.ReactNode;
  onBack: () => void;
  onNext: () => void;
};

const BottomButtonToolBar = ({
  onBack,
  onNext,
  backDisabled = false,
  nextDisabled = false,
  children,
}: Props) => {
  return (
    <div className="bottom-toolbar">
      <QtmButton
        disabled={backDisabled}
        label="Back"
        leftIcon="arrow_back_ios"
        color="primary"
        variant="outline"
        onClick={onBack}
      />
      {children}
      <QtmButton
        disabled={nextDisabled}
        label="Next"
        rightIcon="arrow_forward_ios"
        color="primary"
        variant="outline"
        onClick={onNext}
      />
    </div>
  );
};

export default BottomButtonToolBar;
