type Props = {
  label: string;
  value: string;
};

const PopupChartField = ({ label, value }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.3rem',
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          paddingRight: '1rem',
        }}
      >
        {label}
      </div>
      <div>{value}</div>
    </div>
  );
};

export default PopupChartField;
