import { QtmButton, QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import { useNavigate } from 'react-router-dom';
import AreaIcon from './area.svg?react';
import RegionIcon from './region.svg?react';

const ChooseAreaType = () => {
  const navigate = useNavigate();

  const { isSm } = useTailwindBreakpoints();

  const onRegion = () => {
    navigate('/sat/area/region');
  };

  const onLonLat = () => {
    navigate('/sat/area/lonlat');
  };

  const contentHeight = isSm ? '230px' : '280px';
  const titleSize = isSm ? 'text-scale-6' : 'text-scale-4';

  return (
    <div>
      <QtmTypography classes={clsx(titleSize, 'text-white-100 text-scale-3')}>
        First, you need to select your area of interest.
        <br />
        For this, you have two ways to identify it:
      </QtmTypography>
      <div className="flex justify-around">
        <div className="area-type" onClick={onRegion} style={{ height: contentHeight }}>
          <RegionIcon width="100" height="100" />
          <QtmTypography classes="text-white-100 text-scale-4 my-l">
            By selecting a<br />
            region area
          </QtmTypography>
          <QtmButton
            label="Geographical"
            rightIcon="arrow_forward_ios"
            color="primary"
            variant="outline"
          />
        </div>
        <div className="area-type" onClick={onLonLat} style={{ height: contentHeight }}>
          <AreaIcon width="100" height="100" />
          <QtmTypography classes="text-white-100 text-scale-4 my-l">
            By selecting a<br />
            long/lat area
          </QtmTypography>
          <QtmButton
            label="Longitudes & Latitudes"
            rightIcon="arrow_forward_ios"
            color="primary"
            variant="outline"
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseAreaType;
