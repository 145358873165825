import { QtmButton, QtmIcon, QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import useGetResult from 'modules/results/result-hook';
import { useMatch, useNavigate } from 'react-router-dom';

const ResultHeader = () => {
  const navigate = useNavigate();
  const { isSm } = useTailwindBreakpoints();
  const { id, data } = useGetResult();

  const matchRevistTime = !!useMatch(`/results/${id}/revisit-time`);
  const matchAnimation = !!useMatch(`/results/${id}/animation`);
  const matchOpportunities = !!useMatch(`/results/${id}/opportunities`);

  const onBack = () => {
    if (id) {
      navigate(`/results/${id}/main`);
    }
  };

  const onClick = (path: string) => () => {
    navigate(path);
  };

  if (isSm) {
    return (
      <div className="result-header-sm">
        <div className="my-s">
          <QtmTypography component="subtitle-2" classes="text-white-100">
            {data?.constellationConfiguration?.name}
          </QtmTypography>
        </div>
        <div className="actions">
          <div onClick={onBack}>
            <QtmIcon icon="arrow_back_ios" size="xlarge" />
          </div>
          <div
            className={clsx({ active: matchAnimation })}
            onClick={onClick(`/results/${id}/animation`)}
          >
            <QtmIcon icon="play_circle" size="xlarge" />
          </div>
          <div
            className={clsx({ active: matchRevistTime })}
            onClick={onClick(`/results/${id}/revisit-time`)}
          >
            <QtmIcon icon="map" size="xlarge" />
          </div>
          <div
            className={clsx({ active: matchOpportunities })}
            onClick={onClick(`/results/${id}/opportunities`)}
          >
            <QtmIcon icon="analytics" size="xlarge" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="result-header">
      <div className="brand">
        <QtmButton
          size="medium"
          disabled={false}
          label="Back"
          leftIcon="arrow_back_ios"
          color="primary"
          variant="outline"
          onClick={onBack}
        />
        <QtmTypography component="subtitle-1" classes="text-white-100 ml-l">
          {data?.constellationConfiguration?.name}
        </QtmTypography>
      </div>
      <div className="actions">
        <div
          className={clsx({ active: matchAnimation })}
          onClick={onClick(`/results/${id}/animation`)}
        >
          <QtmIcon icon="play_circle" size="xlarge" />
        </div>
        <div
          className={clsx({ active: matchRevistTime })}
          onClick={onClick(`/results/${id}/revisit-time`)}
        >
          <QtmIcon icon="map" size="xlarge" />
        </div>
        <div
          className={clsx({ active: matchOpportunities })}
          onClick={onClick(`/results/${id}/opportunities`)}
        >
          <QtmIcon icon="analytics" size="xlarge" />
        </div>
      </div>
    </div>
  );
};

export default ResultHeader;
