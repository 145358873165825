import Point from '@arcgis/core/geometry/Point';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AnimationOpportunity } from 'modules/results/animation/AnimationOpportunities/AnimationOpportunitiesType';

import { useEffect, useRef, useState } from 'react';

import { setCurrentDate } from 'shared/reducers/animationSlice';
import { getLocaleDateFromPosition } from 'utils/map.utils';

type Props = {
  opp: AnimationOpportunity;
  prevOpp?: AnimationOpportunity;
};

const AnimationOpportunityItem = ({ opp, prevOpp }: Props) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const currentDate = useAppSelector(({ animation }) => animation.currentDate);

  const [oppDate, setOppDate] = useState<Dayjs>();
  const [showDate, setShowDate] = useState(true);
  const [isNext, setIsNext] = useState(false);

  useEffect(() => {
    if (prevOpp) {
      const prevDate = new Date(prevOpp.start);
      const currDate = new Date(opp.start);
      setShowDate(prevDate.getDay() !== currDate.getDay());
    }

    if (opp.isUtc) {
      setOppDate(dayjs(opp.start));
    } else {
      const loadLocalDate = async () => {
        const point = new Point({
          latitude: opp.latitude,
          longitude: opp.longitude,
        });
        const localDate = await getLocaleDateFromPosition(dayjs(opp.start), point);
        setOppDate(localDate);
      };
      loadLocalDate();
    }
  }, [opp]);

  useEffect(() => {
    if (isNext && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [isNext]);

  const selected = currentDate === new Date(opp.start).getTime();
  const before = currentDate && currentDate > new Date(opp.start).getTime();
  const after = currentDate && currentDate < new Date(opp.start).getTime();

  useEffect(() => {
    if (currentDate) {
      if (before) {
        setIsNext(false);
      } else if (after) {
        if (prevOpp) {
          const previousIsCloser = currentDate < new Date(prevOpp.start).getTime();
          setIsNext(!previousIsCloser);
        } else {
          setIsNext(true);
        }
      }
    }
  }, [currentDate]);

  const onClick = () => {
    const oppDate = new Date(opp.start);
    dispatch(setCurrentDate(oppDate.valueOf()));
  };

  const dateFormatted = oppDate && showDate ? oppDate.format('DD/MM/YYYY') : ' ';
  const timeFormatted = oppDate ? oppDate.format('HH:mm') : '';

  return (
    <div
      ref={ref}
      className={classnames('opportunity-item', {
        selected,
        'animate-pulse': isNext,
        before,
        after,
        isNext,
      })}
      onClick={onClick}
    >
      <div className="opp-date">{dateFormatted}</div>
      <div>{timeFormatted}</div>
      <div className="sat-name">{opp.satName}</div>
    </div>
  );
};

export default AnimationOpportunityItem;
