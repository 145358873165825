import { useGetAreaQuery } from 'api/AreaOfInterestEndPoints';
import {
  useGetConstellationsResultQuery,
  useGetFileResultQuery,
} from 'api/ConstellationResultEndPoints';
import { useParams } from 'react-router-dom';

const useGetResult = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetConstellationsResultQuery(
    {
      id,
      filter: {
        include: ['constellationConfiguration'],
      },
    },
    {
      skip: !id,
    },
  );

  const { data: file, isLoading: isLoadingFile } = useGetFileResultQuery(id ?? '', {
    skip: !id,
  });

  const { data: area, isLoading: isLoadingArea } = useGetAreaQuery(Number(data?.areaOfInterestId), {
    skip: !data?.areaOfInterestId,
  });

  return {
    id,
    data,
    isLoading,
    file,
    isLoadingFile,
    area,
    isLoadingArea,
  };
};

export default useGetResult;
