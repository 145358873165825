import classNames from 'classnames';

import { useMemo, useState } from 'react';

import SceneView from '@arcgis/core/views/SceneView';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import cloneDeep from 'lodash/cloneDeep';
import useGetResult from 'modules/results/result-hook';
import { PointOfInterest } from 'shared/model/resultFile.model';
import { setSelection } from 'shared/reducers/animationSlice';
import { sortByName } from 'utils/array-utils';
import './AnimationPoiBtn.css';

type Props = {
  view: SceneView;
};

const AnimationPoiBtn = ({ view }: Props) => {
  const dispatch = useAppDispatch();
  const selection = useAppSelector(({ animation }) => animation.selection);

  const { area } = useGetResult();

  const { isSm } = useTailwindBreakpoints();
  const { file } = useGetResult();

  const [show, setShow] = useState(false);

  const fakeUTCPoi: PointOfInterest = {
    id: -1,
    name: area?.name || 'UTC',
    latitude: 0,
    longitude: 0,
    satellites: [],
  };

  const items = useMemo(() => {
    if (file) {
      const points = cloneDeep(file.pointOfInterest);
      points.sort(sortByName());

      const selected = points.find(item => item.id === selection?.id);

      if (selected) {
        points.push(fakeUTCPoi);
      }
      return points;
    }
    return [];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, selection, area]);

  const selectedItem = items.find(item => item.id === selection?.id);

  const toggleShow = () => {
    if (items.length > 0) {
      setShow(!show);
    }
  };

  const onSelect = (item: PointOfInterest) => () => {
    setShow(false);
    if (item.id > 0) {
      dispatch(setSelection(item));
    } else {
      dispatch(setSelection(null));
    }
  };

  const availableSelection = items.filter(item => item.id !== selectedItem?.id);

  let top = -availableSelection.length * 30 + 'px';
  if (isSm) {
    top = (availableSelection.length - 1) * 30 + 'px';
  }

  const text = selectedItem ? selectedItem.name : fakeUTCPoi.name;

  return (
    <div className={clsx('poi', { isSm })}>
      {show && (
        <div className="poi-selector" style={{ top }}>
          {availableSelection.map(item => (
            <div key={item.id} className="poi-selector-item" onClick={onSelect(item)}>
              <div className="hover-white">{item.name}</div>
            </div>
          ))}
        </div>
      )}
      <div className={classNames('poi-btn hover-white', { show })} onClick={toggleShow}>
        <div>{text}</div>
      </div>
    </div>
  );
};

export default AnimationPoiBtn;
