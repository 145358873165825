import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { SimpleFillSymbol } from '@arcgis/core/symbols';
import { useGetAreasQuery } from 'api/AreaOfInterestEndPoints';
import { useAppSelector } from 'hooks/redux';
import useGetResult from 'modules/results/result-hook';
import { useEffect } from 'react';
import { AreaOfInterest } from 'shared/model/AreaOfInterest.model';

export const areaOfInterestslayer = new GraphicsLayer({
  id: 'areaOfInterests',
  title: 'Area of interets',
  elevationInfo: {
    mode: 'on-the-ground',
  },
});

export const loadZoi = (area: AreaOfInterest) => {
  const polygon = Polygon.fromJSON(area.geom);

  areaOfInterestslayer.removeAll();
  const graphic = new Graphic({
    geometry: polygon,
    symbol: new SimpleFillSymbol({
      color: [35, 162, 204, 0.1],
      outline: {
        width: 2,
        color: [35, 162, 204, 1],
      },
    }),
    attributes: {
      id: area.id,
      name: area.name,
    },
  });
  areaOfInterestslayer.add(graphic);
};

export const useLoadZoiFromResult = () => {
  const selection = useAppSelector(({ animation }) => animation.selection);

  const { file } = useGetResult();

  const mission = file?.areaOfInterest.missions[0];

  const { data } = useGetAreasQuery(
    {
      where: {
        id: mission?.id,
      },
    },
    {
      skip: !mission,
    },
  );

  useEffect(() => {
    if (data && data.length > 0) {
      if (selection) {
        areaOfInterestslayer.removeAll();
      } else {
        loadZoi(data[0]);
      }
    }
  }, [data, selection]);

  return data;
};
