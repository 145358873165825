import { QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import isNumber from 'lodash/isNumber';

const RevisitTimeSummary = () => {
  const revisitTime = useAppSelector(({ selection }) => selection.revisitTime);
  const { isSm } = useTailwindBreakpoints();

  if (!isNumber(revisitTime)) {
    return null;
  }

  const hSuffix = isSm ? 'h' : 'hours';
  const minSuffix = isSm ? 'min' : 'minutes';

  const hours = revisitTime === 0 ? `30 ${minSuffix}` : `${revisitTime} ${hSuffix}`;

  return (
    <QtmTypography
      classes={clsx('text-white-100 font-helvetica', {
        'text-scale-6': isSm,
        'text-scale-5': !isSm,
      })}
    >
      Revisit Time {hours}
    </QtmTypography>
  );
};

export default RevisitTimeSummary;
