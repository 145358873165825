import { ScatterPlotDatum, ScatterPlotTooltipProps } from '@nivo/scatterplot';
import { AcquisitionOppMetadata2 } from 'modules/results/opportunities/AcquisitionOppMetadata';
import PopupChartField from 'modules/results/opportunities/PopupChartField';

const NivoChartToolTip = ({ node }: ScatterPlotTooltipProps<ScatterPlotDatum>) => {
  //@ts-ignore
  const metaData = node.data.z as AcquisitionOppMetadata2;

  const localDate = metaData.localDate;
  const hour = localDate.format('HH:mm');

  const dayFromStart = localDate.diff(metaData.startDate, 'day') + 1;

  let previousOppStr = '';
  if (metaData.previousOppDate) {
    if (!localDate.isSame(metaData.previousOppDate, 'day')) {
      previousOppStr += '(D-1) ';
    }
    previousOppStr += metaData.previousOppDate.format('HH:mm');
  }

  let previousSatDiff = '';
  if (metaData.previousSatDate) {
    const previousSatDate = metaData.previousSatDate;
    if (!localDate.isSame(previousSatDate, 'day')) {
      previousSatDiff += '(D-1) ';
    }
    previousSatDiff += previousSatDate.format('HH:mm');
  }
  console.log(node.x, node.y);

  const translateX = node.x > 400 ? '-50%' : '50%';
  const translateY = node.y < 20 ? '25%' : '5%';

  return (
    <div
      style={{
        borderRadius: '0.5rem',
        transform: `translate(${translateX}, ${translateY})`,
        background: 'var(--color-bluegrey-900) !important',
        padding: '0.6rem',
      }}
    >
      <div className="font-weight-bold mb-2 h6" style={{ color: node.color }}>
        {node.id}
      </div>
      <PopupChartField label="Simulation day" value={dayFromStart + ''} />
      <PopupChartField label="Opportunity" value={hour} />

      {previousOppStr && <PopupChartField label="Previous opportunity" value={previousOppStr} />}
      {previousSatDiff && <PopupChartField label="Previous Satellite" value={previousSatDiff} />}
    </div>
  );
};

export default NivoChartToolTip;
