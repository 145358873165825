import { QtmSlider, QtmTypography } from '@qtm/react';
import { QtmSliderCustomEvent, SliderValue } from '@qtm/web-components';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import isNumber from 'lodash/isNumber';
import { Navigate, useNavigate } from 'react-router-dom';
import BottomButtonToolBar from 'shared/compoments/Buttons/BottomButtonToolBar';
import { defaultRevisitTime, setRevisitTime } from 'shared/reducers/selectionSlice';
import './revisit.css';

//const availableRevisitTimes = [24, 18, 12, 6, 3, 1];
export const availableRevisitTimes = [0, 3, 6, 12, 18, 24];

const marks = availableRevisitTimes.map(value => {
  const label = value === 0 ? '0.5h' : value + 'h';

  return {
    label,
    value,
  };
});

const ChooseRevisit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isSm } = useTailwindBreakpoints();
  const areaId = useAppSelector(({ selection }) => selection.areaId);
  const revisitTime = useAppSelector(({ selection }) => selection.revisitTime);

  if (!isNumber(areaId)) {
    return <Navigate to="/sat/area/type" />;
  }

  const onBack = async () => {
    if (!isNumber(revisitTime)) {
      await dispatch(setRevisitTime(defaultRevisitTime));
    }
    navigate('/sat/area/type');
  };

  const onNext = async () => {
    if (!isNumber(revisitTime)) {
      await dispatch(setRevisitTime(defaultRevisitTime));
    }
    navigate('/sat/ratio/3');
  };

  const onChange = (event: QtmSliderCustomEvent<SliderValue>) => {
    if (!Array.isArray(event.detail)) {
      dispatch(setRevisitTime(event.detail));
    }
  };

  const revisitTimeValue = isNumber(revisitTime) ? revisitTime : defaultRevisitTime;

  const titleSize = isSm ? 'title-3' : 'title-2';
  const subTitleSize = isSm ? 'text-scale-5' : 'text-scale-4';

  return (
    <>
      <QtmTypography component={titleSize} classes="text-white-100">
        <span className="hidden md:inline">Set your Revisit Time</span>
        <span className="xs:inline md:hidden">Revisit Time</span>
      </QtmTypography>
      <QtmTypography classes={`${subTitleSize} text-white-100 font-helvetica `}>
        You will set your Revisit Time. <br />
        It’s the average time between two passes on your Area of Interest.
        <br />
        It’s needs to be in line with your observation mission.
      </QtmTypography>

      <div className={clsx('w-full px-l')}>
        <div className={clsx('ratio-slider', { isSm })}>
          <QtmSlider
            anchorPoint={availableRevisitTimes[0]}
            color="primary"
            format-metric={null}
            legendPosition="top"
            marks={marks}
            max={availableRevisitTimes[availableRevisitTimes.length - 1]}
            min={availableRevisitTimes[0]}
            size="large"
            step={null}
            value={revisitTimeValue}
            onValueChanged={onChange}
          />
        </div>
      </div>

      <BottomButtonToolBar onBack={onBack} onNext={onNext} />
    </>
  );
};

export default ChooseRevisit;
