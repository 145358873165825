import Polygon from '@arcgis/core/geometry/Polygon';
import { useAppSelector } from 'hooks/redux';
import { AnimationOpportunity } from 'modules/results/animation/AnimationOpportunities/AnimationOpportunitiesType';
import AnimationOpportunityItem from 'modules/results/animation/AnimationOpportunities/AnimationOpportunityItem';
import useGetResult from 'modules/results/result-hook';

import { useMemo } from 'react';

const maxOpps = import.meta.env.VITE_MAX_OPPS;

function AnimationOpportunitiesList() {
  const selection = useAppSelector(({ animation }) => animation.selection);

  const { file, area } = useGetResult();

  const poi = file?.pointOfInterest.find(poi => poi.id === selection?.id);

  const opportunities = useMemo(() => {
    const result: AnimationOpportunity[] = [];
    if (poi) {
      poi.satellites.forEach(sat => {
        sat.values.forEach(value => {
          result.push({
            ...value,
            satId: sat.satId,
            satName: sat.name,
            latitude: poi.latitude,
            longitude: poi.longitude,
          });
        });
      });
    } else if (area) {
      const polygon = Polygon.fromJSON(area.geom);
      const centroid = polygon.centroid;

      const areaAccess = file?.areaOfInterestAccess ?? [];
      areaAccess.forEach(access => {
        access.satellites.forEach(sat => {
          sat.values.forEach(value => {
            result.push({
              ...value,
              satId: sat.satId,
              satName: sat.name,
              latitude: centroid.latitude,
              longitude: centroid.longitude,
              isUtc: true,
            });
          });
        });
      });
    }

    result.sort((a, b) => a.start.localeCompare(b.start));

    return result;
  }, [poi, area]);

  if (!poi && opportunities.length > maxOpps) {
    return null;
  }

  return (
    <div className="animation-opportunities-list">
      {opportunities.map((opp, i) => {
        const prevOpp = i > 0 ? opportunities[i - 1] : undefined;
        const key = opp.start + '_' + opp.satId + '_' + opp.satName;

        return <AnimationOpportunityItem key={key} opp={opp} prevOpp={prevOpp} />;
      })}
    </div>
  );
}

export default AnimationOpportunitiesList;
