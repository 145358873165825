import Graphic from '@arcgis/core/Graphic';
import ArcGisMap from '@arcgis/core/Map';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import SceneView from '@arcgis/core/views/SceneView';
import LayerList from '@arcgis/core/widgets/LayerList';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import AnimationControlPanel from 'modules/results/animation/controlPanel/AnimationControlPanel';
import AnimationPoiBtn from 'modules/results/animation/controlPanel/AnimationPoiBtn';
import {
  animationSatLayer,
  getSatAreaAccess,
  getSatelliteGraphicsAtDate,
  getSatPoiAccess,
} from 'modules/results/animation/utils/animation-utils';
import { poiLayer, useLoadPoisFromResult } from 'modules/results/animation/utils/poi-utils';
import {
  areaOfInterestslayer,
  useLoadZoiFromResult,
} from 'modules/results/animation/utils/zoi-utils';
import useGetResult from 'modules/results/result-hook';
import { useEffect, useRef, useState } from 'react';
import { setAnimationState } from 'shared/reducers/animationSlice';
import { getSatModelFromSatModelId } from 'utils/result-utils';

const MAX_ALTITUDE = 12000000000; // meters

type Props = {
  className?: string;
};

const ResultAnimationMap = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const start = useAppSelector(({ animation }) => animation.start);
  const selection = useAppSelector(({ animation }) => animation.selection);

  const { isSm } = useTailwindBreakpoints();

  const mapRef = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<ArcGisMap>();
  const [view, setView] = useState<SceneView>();

  const { file } = useGetResult();

  const areas = useLoadZoiFromResult();
  useLoadPoisFromResult();

  useEffect(() => {
    if (mapRef.current && !map) {
      const aMap = new ArcGisMap({
        basemap: 'dark-gray',
        ground: 'world-elevation',
        layers: [poiLayer, areaOfInterestslayer, animationSatLayer],
      });
      const mapView = new SceneView({
        map: aMap,
        container: mapRef.current,
        camera: {
          position: {
            x: 1,
            y: 1,
            z: 25000000,
          },
          tilt: 0,
        },
        constraints: {
          altitude: {
            max: MAX_ALTITUDE,
          },
        },
        popup: {
          dockEnabled: false,
          dockOptions: {
            buttonEnabled: false,
          },
          viewModel: {
            includeDefaultActions: false,
          },
        },
      });

      const layerList = new LayerList({
        view: mapView,
      });
      //mapView.ui.add(layerList, 'top-right');

      setMap(aMap);
      setView(mapView);
    }
    return () => {
      if (map) {
        map.destroy();
      }
    };
  }, [mapRef]);

  useEffect(() => {
    const loadGraphics = async () => {
      if (map && start) {
        const startDate = dayjs(start);
        dispatch(setAnimationState('loading'));
        animationSatLayer.removeAll();

        if (!file) {
          dispatch(setAnimationState('disabled'));
          return;
        }

        let graphics: Graphic[] = [];
        const keplerianOrbitalElements = file.keplerianOrbitalElements ?? [];
        if (keplerianOrbitalElements.length > 0) {
          keplerianOrbitalElements.map(item => {
            const satModel = getSatModelFromSatModelId(file, item.satelliteModelId);
            const areaAccess = getSatAreaAccess(file, item.planeId, item.satId);
            const poiAccess = getSatPoiAccess(file, item.planeId, item.satId);
            const satGraphics = getSatelliteGraphicsAtDate(
              satModel,
              item,
              areaAccess,
              poiAccess,
              startDate,
            );
            graphics = graphics.concat(satGraphics);
          });

          animationSatLayer.addMany(graphics);
        }

        dispatch(setAnimationState('ready'));
      }
    };

    loadGraphics();
  }, [file, map, start]);

  useEffect(() => {
    if (areaOfInterestslayer && areas && areas.length > 0) {
      view?.when(() => {
        const polygon = Polygon.fromJSON(areas[0].geom);
        view.goTo(polygon);
      });
    }
  }, [areas, view]);

  useEffect(() => {
    if (selection) {
      const geometry = new Point({
        latitude: selection.latitude,
        longitude: selection.longitude,
      });
      view?.goTo(geometry);
    } else if (areas && areas.length > 0) {
      const polygon = Polygon.fromJSON(areas[0].geom);
      view?.goTo(polygon);
    }
  }, [selection]);

  return (
    <div style={{ position: 'relative' }}>
      {view && isSm && <AnimationPoiBtn view={view} />}
      <div className={clsx('result-map', className)} ref={mapRef}></div>
      {view && <AnimationControlPanel view={view} />}
    </div>
  );
};

export default ResultAnimationMap;
