import Point from '@arcgis/core/geometry/Point';
import { ResponsiveScatterPlot, ScatterPlotDatum, ScatterPlotRawSerie } from '@nivo/scatterplot';
import dayjs, { Dayjs } from 'dayjs';
import { AcquisitionOppMetadata2 } from 'modules/results/opportunities/AcquisitionOppMetadata';
import NivoChartToolTip from 'modules/results/opportunities/NivoChartToolTip';
import useGetResult from 'modules/results/result-hook';
import { useEffect, useState } from 'react';

import { PointOfInterest } from 'shared/model/resultFile.model';
import { createArrayWithRange } from 'utils/array-utils';
import { rgbArrayToHex } from 'utils/color-utils';
import { getLocaleDateFromPosition } from 'utils/map.utils';
import { getSatColor } from 'utils/result-utils';

const hoursAxisValues = createArrayWithRange(0, 24);

type Props = {
  poi: PointOfInterest | undefined;
};

const ResultOpportunitiesNivoChart = ({ poi }: Props) => {
  const [data, setData] = useState<ScatterPlotRawSerie<ScatterPlotDatum>[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [days, setDays] = useState<number[]>([]);
  const [oppPerDay, setOppPerDay] = useState<number[]>([]);

  const { file } = useGetResult();

  useEffect(() => {
    if (file && poi) {
      const initChart = async () => {
        const startDate = dayjs(file.startDate);

        const simulationDays = file.duration;
        setDays(createArrayWithRange(0, simulationDays));

        const opportunitiesDaysCount = new Array(simulationDays + 2).fill(0);

        if (poi.satellites) {
          const firstPoint = new Point({
            longitude: poi.longitude,
            latitude: poi.latitude,
          });

          const localStartDate = await getLocaleDateFromPosition(startDate, firstPoint);

          const orderedByDate = poi.satellites.reduce((agg: string[], current) => {
            current.values.forEach(value => {
              agg.push(value.epoch ?? value.start);
            });
            return agg;
          }, []);
          orderedByDate.sort();
          //console.table(orderedByDate);

          const newData: ScatterPlotRawSerie<ScatterPlotDatum>[] = [];
          const newColors: string[] = [];
          for (let i = 0; i < poi.satellites.length; i++) {
            const sat = poi.satellites[i];

            const id = sat.satId;
            const color = getSatColor(file, sat.name);
            newColors.push(rgbArrayToHex(color));

            //const color = sat.name.includes('HRR') ? HRRColor : HROColor;

            const dataSeries: ScatterPlotDatum[] = [];
            for (let j = 0; j < sat.values.length; j++) {
              const satValue = sat.values[j];

              const epoch = dayjs(satValue.epoch);
              const localDate = await getLocaleDateFromPosition(epoch, firstPoint);

              const hours = localDate.get('hours');
              const minutes = localDate.get('minutes');
              const minuteDecimal = minutes / 60;
              const y = hours + minuteDecimal;

              const x = localDate.diff(localStartDate, 'day') + 1;

              const previousItem = j === 0 ? undefined : sat.values[j - 1];
              let previousOppDate: Dayjs | undefined = undefined;
              if (previousItem) {
                const previousDate = dayjs(previousItem.epoch);
                previousOppDate = await getLocaleDateFromPosition(previousDate, firstPoint);
              }

              const previousSatIndex = orderedByDate.findIndex(date => date === satValue.epoch);
              let previousSatDate = undefined;
              if (previousSatIndex > 0) {
                const previousDate = dayjs(orderedByDate[previousSatIndex - 1]);
                previousSatDate = await getLocaleDateFromPosition(previousDate, firstPoint);
              }

              const z: AcquisitionOppMetadata2 = {
                startDate,
                localDate,
                previousOppDate,
                previousSatDate,
              };

              dataSeries.push({
                x,
                y,
                //@ts-ignore
                z,
              });
              opportunitiesDaysCount[x]++;
            }
            newData.push({
              id: sat.name,
              data: dataSeries,
            });
          }
          setColors(newColors);
          setData(newData);
        }
        setOppPerDay(opportunitiesDaysCount);
      };
      if (poi) {
        initChart();
      }
    }
  }, [file, poi]);

  if (data.length === 0 || !file) {
    return null;
  }

  return (
    <div className="result-chart">
      <ResponsiveScatterPlot
        data={data}
        margin={{
          top: 50,
          bottom: 70,
          left: 60,
          right: 10,
        }}
        xScale={{
          type: 'linear',
          min: 0,
          max: file.duration,
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 24,
        }}
        axisTop={{
          legend: 'Total number of oppportunities per day',
          legendPosition: 'middle',
          tickValues: days,
          legendOffset: -40,
          format: (value: number) => oppPerDay[value],
        }}
        axisRight={null}
        axisBottom={{
          tickValues: days,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Simulation Days',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickValues: hoursAxisValues,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Hours during a day',
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        theme={{
          background: 'transparent',
          legends: {
            text: {
              fill: '#ffffff',
            },
          },
          text: {
            color: '#ffffff',
          },
          dots: {
            text: {
              fill: '#ffffff',
            },
          },
          markers: {
            textColor: '#ffffff',
          },
          axis: {
            ticks: {
              text: {
                fontSize: '0.9rem',
                fill: '#ffffff',
              },
            },
            legend: {
              text: {
                fontWeight: 'bold',
                fontSize: '1rem',
                fill: '#ffffff',
              },
            },
          },
        }}
        colors={colors}
        tooltip={NivoChartToolTip}
      />
    </div>
  );
};

export default ResultOpportunitiesNivoChart;
