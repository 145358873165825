import { QtmButton } from '@qtm/react';
import ResultHeader from 'modules/results/ResultHeader';
import ResultOpportunitiesNivoChart from 'modules/results/opportunities/ResultOpportunitiesNivoChart';
import useGetResult from 'modules/results/result-hook';
import { useEffect, useState } from 'react';
import { PointOfInterest } from 'shared/model/resultFile.model';

const ResultOpportunities = () => {
  const { file } = useGetResult();

  const [poi, setPoi] = useState<PointOfInterest>();

  const pois = file?.pointOfInterest ?? [];

  useEffect(() => {
    if (!poi && pois.length > 0) {
      const poiData = pois[0];
      if (poiData) {
        setPoi(poiData);
      }
    }
  }, [pois]);

  const onClick = (poiId: number) => () => {
    const poiData = pois.find(poi => poi.id === poiId);
    if (poiData) {
      setPoi(poiData);
    }
  };

  return (
    <div>
      <ResultHeader />
      <div
        className=""
        style={{
          overflow: 'hidden',
          paddingTop: '10px',
        }}
      >
        <div className="flex justify-center">
          {pois.map(aPoi => (
            <div key={aPoi.id} className="mr-l">
              <QtmButton
                size="medium"
                label={aPoi.name}
                color={poi?.id === aPoi.id ? 'primary' : 'neutral'}
                variant="outline"
                onClick={onClick(aPoi.id)}
              />
            </div>
          ))}
        </div>
        <div className="mt-l">
          <ResultOpportunitiesNivoChart poi={poi} />
        </div>
      </div>
    </div>
  );
};

export default ResultOpportunities;
