import { QtmIcon, QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/redux';
import { formatTimePerfDuration } from 'utils/date-utils';

type Props = {
  revisitTime: number;
  inline?: boolean;
  className?: string;
  compareWithSelection?: boolean;
};

const ConstellationResultRevisitTime = ({
  revisitTime,
  inline = false,
  compareWithSelection = false,
  className,
}: Props) => {
  const duration = formatTimePerfDuration(revisitTime);

  const selectedRevisitTime = useAppSelector(({ selection }) => selection.revisitTime);

  let color = 'text-white-100';
  if (compareWithSelection && selectedRevisitTime) {
    const selection = selectedRevisitTime * 3600;

    const delta = 1 * 3600; // 1 hour

    const isBetter = revisitTime < selection;
    const isNormal = revisitTime >= selection && revisitTime <= selection + delta;
    const isWorse = revisitTime > selection + delta;

    if (isBetter) {
      color = 'text-green-500';
    } else if (isNormal) {
      color = 'text-white';
    } else if (isWorse) {
      color = 'text-orange-500';
    }
  }

  if (inline) {
    return (
      <div className={clsx('flex justify-between items-center', className, color)}>
        <div className="flex">
          <QtmIcon icon="timer" size="large" />
          <QtmTypography className={clsx('text-scale-6  whitespace-nowrap pl-m')}>
            Revisit time:
          </QtmTypography>
        </div>
        <QtmTypography className="text-scale-5 ">{duration}</QtmTypography>
      </div>
    );
  }

  return (
    <div className={clsx('flex items-center', className, color)}>
      <QtmIcon icon="timer" size="xlarge" />
      <div className="ml-m">
        <QtmTypography classes={clsx('text-scale-6  whitespace-nowrap')}>
          Revisit time:
        </QtmTypography>
        <QtmTypography classes={clsx('text-scale-5')}>{duration}</QtmTypography>
      </div>
    </div>
  );
};

export default ConstellationResultRevisitTime;
