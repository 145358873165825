import { QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import { geopoliticalAreaUrl } from 'modules/sat/area/ChooseAreaConstants';
import { useNavigate } from 'react-router-dom';
import BottomButtonToolBar from 'shared/compoments/Buttons/BottomButtonToolBar';
import ArcGis2DMap from 'shared/compoments/map/ArcGis2DMap';
import ArcGis3DMap from 'shared/compoments/map/ArcGis3DMap';

const ChooseAreaRegion = () => {
  const navigate = useNavigate();
  const { isSm } = useTailwindBreakpoints();
  const areaId = useAppSelector(({ selection }) => selection.areaId);

  const onBack = () => {
    navigate('/sat/area/type');
  };

  const onNext = () => {
    navigate('/sat/revisit/2');
  };

  const show3d = true;

  return (
    <div>
      <QtmTypography classes="text-white-100 text-scale-4 font-helvetica mb-m">
        <span className="hidden md:inline">Select an area among the 17 regions and 7 oceans:</span>
        <span className="xs:inline md:hidden">Select an area</span>
      </QtmTypography>
      <div className="summary-panel">
        {show3d ? (
          <ArcGis3DMap
            className={clsx('map-3D', { isSm })}
            url={geopoliticalAreaUrl}
            center={[0, 10]}
            geopolitique
          />
        ) : (
          <ArcGis2DMap
            className={clsx('map-2D', { isSm })}
            url={geopoliticalAreaUrl}
            center={[0, 10]}
            geopolitique
          />
        )}
        <div className="content">
          <BottomButtonToolBar onBack={onBack} onNext={onNext} nextDisabled={!areaId} />
        </div>
      </div>
    </div>
  );
};

export default ChooseAreaRegion;
