import { QtmProgressCircle, QtmTypography } from '@qtm/react';
import { useGetConstellationsResultsQuery } from 'api/ConstellationResultEndPoints';
import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import ConstellationResultItem from 'modules/sat/constellations/ConstellationResultItem';
import { Scrollbars } from 'react-custom-scrollbars';
import { Navigate, useNavigate } from 'react-router-dom';
import useCriteriaLog from 'shared/log/CriteriaLogHook';
import './constellations.css';

// something thales whats to show all results
const showAll = false;

const ratioRanges = {
  25: [20, 33],
  50: [40, 60],
  75: [66, 80],
};

const ChooseConstellationResult = () => {
  const navigate = useNavigate();
  const { isSm } = useTailwindBreakpoints();
  const areaId = useAppSelector(({ selection }) => selection.areaId);
  const revisitTime = useAppSelector(({ selection }) => selection.revisitTime);
  const ratio = useAppSelector(({ selection }) => selection.ratio);

  useCriteriaLog();

  //const gte = revisitTime ? revisitTime * 3600 : 0;
  const range = get(ratioRanges, ratio ?? '', ratio);

  const ratioFilter = Array.isArray(range)
    ? {
        between: range,
      }
    : {
        eq: ratio,
      };

  const { data, isLoading } = useGetConstellationsResultsQuery(
    {
      include: ['constellationConfiguration'],
      where: showAll
        ? {
            areaOfInterestId: {
              eq: areaId as number,
            },
          }
        : {
            /*revisitTime: {
              gte,
            },*/
            areaOfInterestId: {
              eq: areaId as number,
            },
            //@ts-ignore
            'constellationConfiguration.ratioRadarOptique': ratioFilter,
          },
      order: ['revisitTime ASC'],
    },
    {
      skip: !areaId || !isNumber(revisitTime) || !isNumber(ratio),
    },
  );

  const constellations = data ?? [];

  if (!areaId || !isNumber(revisitTime) || !isNumber(ratio)) {
    return <Navigate to="/sat/area/type" />;
  }

  const onBack = () => {
    navigate('/sat/constellations/4');
  };

  const nearestRevisitTime = constellations.reduce((acc, item) => {
    if (Math.abs(item.revisitTime - revisitTime * 3600) < Math.abs(acc - revisitTime * 3600)) {
      return item.revisitTime;
    }

    return acc;
  }, 0);

  const nearestRevisitTimeId = constellations.find(
    item => item.revisitTime === nearestRevisitTime,
  )?.id;

  const scrollToNearest = (ref: any) => {
    if (Number(ref?.id) == nearestRevisitTimeId) {
      setTimeout(() => {
        ref.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };

  const titleSize = isSm ? 'title-3' : 'title-2';
  const subTitleSize = isSm ? 'text-scale-5' : 'text-scale-4';

  return (
    <>
      <QtmTypography component={titleSize} classes="text-white-100">
        <span className="hidden md:inline">Configurations of Constellations</span>
        <span className="xs:inline md:hidden">Constellations</span>
      </QtmTypography>
      <QtmTypography classes={`${subTitleSize} text-white-100 font-helvetica`}>
        Below you will find the most relevant configurations
        <br />
        to achieve your desired revisit time.
      </QtmTypography>
      <div className="flex justify-between items-end mt-l ">
        <Scrollbars
          className="constellation-list full-width"
          autoHide
          autoHeight
          autoHeightMax="calc(100vh - 410px)"
        >
          {isLoading ? (
            <div className="h-full w-full flex flex-col items-center justify-center">
              <QtmProgressCircle classes="" track size="xlarge" />
            </div>
          ) : (
            <>
              {constellations.map(item => (
                <div key={item.id} id={item.id + ''} ref={scrollToNearest}>
                  <ConstellationResultItem item={item} compareWithSelection />
                </div>
              ))}
              {constellations.length === 0 && (
                <div className="h-full w-full flex flex-col items-center justify-center">
                  <QtmTypography classes="text-scale-4 text-white-100 text-center">
                    No results found
                  </QtmTypography>
                </div>
              )}
            </>
          )}
        </Scrollbars>
      </div>
    </>
  );
};

export default ChooseConstellationResult;
