import { QtmTypography } from '@qtm/react';
import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import isNumber from 'lodash/isNumber';
import AreaSummary from 'modules/sat/constellations/summary/AreaSummary';
import RatioSummary from 'modules/sat/constellations/summary/RatioSummary';
import RevisitTimeSummary from 'modules/sat/constellations/summary/RevistTimeSummary';
import { Navigate, useNavigate } from 'react-router-dom';
import BottomButtonToolBar from 'shared/compoments/Buttons/BottomButtonToolBar';

const ChoiceSummary = () => {
  const navigate = useNavigate();
  const { isSm } = useTailwindBreakpoints();
  const areaId = useAppSelector(({ selection }) => selection.areaId);
  const revisitTime = useAppSelector(({ selection }) => selection.revisitTime);
  const ratio = useAppSelector(({ selection }) => selection.ratio);

  if (!areaId || !isNumber(revisitTime) || !isNumber(ratio)) {
    return <Navigate to="/sat/area/type" />;
  }

  const onBack = () => {
    navigate('/sat/ratio/3');
  };

  const onNext = () => {
    navigate('/sat/constellations/4/selection');
  };

  const titleSize = isSm ? 'title-3' : 'title-2';

  return (
    <>
      <QtmTypography component={titleSize} classes="text-white-100">
        <span className="hidden md:inline">Summary of your needs</span>
        <span className="xs:inline md:hidden">Your needs</span>
      </QtmTypography>
      <div className="summary-panel mt-s">
        <AreaSummary />
        <div className="content">
          <BottomButtonToolBar onBack={onBack} onNext={onNext}>
            <RevisitTimeSummary />
            <RatioSummary />
          </BottomButtonToolBar>
        </div>
      </div>
    </>
  );
};

export default ChoiceSummary;
